import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"
import CaseDetail from "../../components/Shared/casedetails"
import factoryandwave from "../../assets/images/services/case-study/factoryandwave.png"
import migationtranformation from "../../assets/images/services/case-study/migation-tranformation.png"

const seoTitle = "Migration Factory"
const pageTitle = "Migration Factory"
const subTitle = "Cloud"
const para =
  "Assessing application complexity involves evaluating various factors related to the application's architecture, compliance requirements, operating environnment, and business considerations. This includes understanding the technical constraints, number of dependencies, tiers, statefulness, performance requirements, geographic dependencies, business criticality, user factors, TCO, compliance requirements, IT operations, and the desired operating environment.  "
const blockquote = `"Aligning what needs to be built,with what needs to move, poses as significant challenge to migration velocity and success."`

const cardItems = [
  {
    id: 1,
    heading: `What is a Wave?`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong> Wave 1:{" "} </strong>Applications with high business value and low effort to implement. These applications are ideal candidates for early migrations or proof-of-concepts. They offer significant business benefits and can be migrated relatively easily, making them suitable for early wins and building momentum.
        </li>
        <li>
          <Icon.Square />
          <strong>Wave 2:{" "}</strong> Applications with high business value but high effort to implement. These applications may require more time, resources, and planning due to their complexity or dependencies. While they provide substantial business value, they may require additional preparation and coordination before migration.

        </li>
        <li>
          <Icon.Square />
          <strong>  Wave 3:{" "}</strong> Applications with low business value but low effort to implement. These applications may not be as critical to the business, but they can still be migrated with minimal effort and resources. They can be prioritized after addressing higher-value applications, as they contribute to overall migration progress without significant disruption.
        </li>
        <li>
          <Icon.Square />
          <strong> Wave N:{" "} </strong>Applications with low business value and high effort to implement. These applications have limited business impact and require significant effort to migrate. They should be prioritized last in the migration process, focusing on higher-value applications first. While they may still need to be migrated eventually, they can be deferred until the more critical applications have been successfully migrated.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `A wave-based, factory model to provide a structured and efficient migration framework`,
    listItems: (
      <>
      <p>By dividing the migration process into waves, each representing a set of workloads, and establishing a dedicated migration factory with
specialized expertise, standardized processes, and tools, the migration factory optimizes resource allocation, ensures consistent execution of
migrations, fosters knowledge sharing, and tracks key metrics enabling organizations to manage large-scale migrations with better control,
scalability, and quality.</p>
        <li>
          <Icon.Square />
          <strong> Wave-based:{" "}</strong>Each wave representing a specific set of workloads or applications to be migrated. This wave-based approach helps manage dependencies, prioritize workloads, and facilitate incremental progress.
        </li>
        <li>
          <Icon.Square />
          <strong>Migration Factory:{" "}</strong>A dedicated team or group responsible for executing the migrations. The migration factory operates as a centralized unit with specialized migration expertise, standardized processes, and access to migration tools and resources.
        </li>
        <li>
          <Icon.Square />
          <strong>Embrace Change:{" "}</strong>A dedicated team or group responsible for executing the migrations. The migration factory operates as a centralized unit with specialized migration expertise, standardized processes, and access to migration tools and resources.
        </li>

      </>
    ),
  },
  {
    id: 3,
    heading: `Overview of the Factory/Wave Approach`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong>Efficiency and Scalability:{" "}</strong> Streamlines and automates migration processes, leveraging reusable templates, scripts, and tools. This improves efficiency and scalability by enabling consistent and rapid execution of migrations across multiple waves simultaneously.
        </li>
        <li>
          <Icon.Square />
          <strong>Resource Optimization:{" "}</strong> Optimizes resource allocation by having a pool of skilled resources that can be assigned to different waves based on workload priorities and requirements. This ensures efficient utilization of resources and accelerates migration timelines. 

        </li>
        <li>
          <Icon.Square />
          <strong>Standardization & Quality Control:{" "}</strong> Establishes standardized migration processes, methodologies, and quality control mechanisms to ensure consistent execution of migrations, adherence to best practices, and high-quality outcomes across each waves. 
        </li>
        <li>
          <Icon.Square />
          <strong>Knowledge Sharing & Collaboration: {" "}</strong>Fosters knowledge sharing and collaboration among migration specialists who exchange insights, lessons learned, and best practices, promoting continuous learning and improvement throughout the migration process. 

        </li>
        <img src={factoryandwave}/>

      </>
    ),
  },
  {
    id: 4,
    heading: `Migrations are Transformations `,
    listItems: (
      <>
        <li>
        <Icon.Square />
        <strong>Governance:{" "}</strong>Effectively define each Migration Wave through collaboration with Application Teams, Core Services, and Business Stakeholders to drive overall Migration Roadmap.
        </li>
        <li>
        <Icon.Square />
        <strong>Core Services:{" "}</strong>Prior to each wave, Core Services creates and updates the core foundational services, while during each wave, they build reusable components that facilitate the migration.
        </li>
        <li>
        <Icon.Square />
        <strong>Applications:{" "}</strong>Responsible for aligning business deliverables with the migration roadmap, gaining a clear understanding of the applications within their portfolio, and facilitating the alignment of those applications to the future state.
        </li>
        <li>
        <Icon.Square />
        <strong>Collaboration:{" "}</strong>"Trailblazers" are defined through collaborative efforts across all groups to identify and align the application ecosystem with the migration roadmap. This process often involves using "t-shirt sizing" to facilitate effective communication and decision-making.
        </li>
        <img src={migationtranformation}/>

      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query MigrationFactoryQuery {
    casedetailsJson(slug: { eq: "migration-factory" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
